
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { NextPage } from 'next'
import Head from 'next/head'
import { Grid, Divider, NextLinkButton } from 'ui'
import { WebsiteLayout } from '@/layouts/WebsiteLayout'
import useTranslation from 'next-translate/useTranslation'
import { HowItWorksSection } from '@/components/how-it-works/HowItWorksSection'
import { CTASectionWrapper } from '@/components/wrappers/CTASectionWrapper'
import { FlatTextCTA } from '@/components/ctas/FlatTextCTA'
import { DemoModalToggle } from '@/components/DemoModalToggle'
import { CONTACT_URL, buildCanonicalAndLangSeoProps } from 'src/assets/links'
import { AllSystemsGoSection } from '@/components/sections/AllSystemsGoSection'
import { ElectricityCompaniesSection } from '@/components/sections/ElectricityCompaniesSection'
import { SplashSection } from '@/components/sections/splash-section/SplashSection'
import { NextSeo } from 'next-seo'

const d = {
  mainTitle: 'tensor-website-landing:main-title',
  mainSub: 'tensor-website-landing:main-sub',
  mainCta: 'tensor-website-landing:main-cta',
  ctaTitle: 'tensor-website-landing:cta-title',
  ctaContactUs: 'tensor-website-landing:cta-contact-us',
  ctaDemo: 'tensor-website-landing:cta-demo',
  seoTitle: 'tensor-website-landing:seo.title',
  seoDescription: 'tensor-website-landing:seo.description',
}

const dividerStyles = {
  width: ['calc(100% - 48px)', '600px'],
  placeSelf: 'center',
  my: 0,
}

const Home: NextPage = () => {
  const { t, lang } = useTranslation()
  return (
    <>
      <Head>
        <title>{t('tensor-website-common:pages.home')}</title>
      </Head>
      <NextSeo
        title={t(d.seoTitle)}
        description={t(d.seoDescription)}
        {...buildCanonicalAndLangSeoProps(lang)}
      />
      <WebsiteLayout>
        <Grid style={{ gap: 0, placeItems: 'center' }}>
          <SplashSection />
          <ElectricityCompaniesSection />
          <AllSystemsGoSection />
          <Divider style={{ ...dividerStyles }} />
          <HowItWorksSection />
          <Divider style={{ ...dividerStyles }} />
          <CTASectionWrapper style={{ pt: [5, 9], pb: [6, 9] }}>
            <FlatTextCTA titleI18nKey={d.ctaTitle}>
              <Grid gap={5} columns={['1fr', null, 'repeat(2, max-content)']}>
                <DemoModalToggle size="lg" buttonText={t(d.ctaDemo)} />
                <NextLinkButton
                  linkProps={{
                    href: CONTACT_URL,
                  }}
                  buttonProps={{
                    size: 'lg',
                    variant: 'secondary',
                  }}
                >
                  {t(d.ctaContactUs)}
                </NextLinkButton>
              </Grid>
            </FlatTextCTA>
          </CTASectionWrapper>
        </Grid>
      </WebsiteLayout>
    </>
  )
}

export default Home


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  