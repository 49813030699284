/** @jsxImportSource theme-ui */
import { FC, PropsWithChildren } from 'react'
import { StyledProps } from 'typ'
import { Grid, Box } from 'ui'

export interface CTASectionWrapperProps
  extends PropsWithChildren,
    StyledProps {}
export const CTASectionWrapper: FC<CTASectionWrapperProps> = ({
  children,
  style,
}) => (
  <Grid
    style={{ width: '100%', height: 'max-content', py: [7, 9], ...style }}
    as="section"
  >
    <Box style={{ mx: 'auto' }} as="article">
      {children}
    </Box>
  </Grid>
)
