/** @jsxImportSource theme-ui */
import { FC } from 'react'
// import useTranslation from 'next-translate/useTranslation'
import { Grid, Text } from 'ui'
// import Image from 'next/image'
// import TokyoCenturyLogo from 'public/images/logos/tokyo-century-bw.png'
import Trans from 'next-translate/Trans'

export const ElectricityCompaniesSection: FC = () => (
  <Grid
    as="section"
    style={{
      width: '100%',
      placeItems: 'center',
      textAlign: 'center',
      px: 4,
      py: 7,
      my: ['64px', '55px'],
      gap: [5, 6],
      borderWidth: '1px 0px',
      borderStyle: 'solid',
      borderColor: 'steel3',
    }}
  >
    <Text
      variant="capsHeader"
      style={{
        fontSize: ['19px', '21px'],
        lineHeight: ['29px', '33px'],
        fontWeight: 'medium',
        color: 'primaryShade1',
        maxWidth: '783px',
      }}
    >
      <Trans
        i18nKey="tensor-website-landing:electricity-companies-title"
        components={{
          nowrap: <span sx={{ whiteSpace: 'nowrap' }} />,
        }}
      />
    </Text>
    {/* <Image
      src={TokyoCenturyLogo}
      width={180}
      height={38}
      alt="Tokyo Century Logo"
    /> */}
  </Grid>
)
