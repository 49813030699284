/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { useTheme } from 'theme'
// eslint-disable-next-line no-restricted-imports
import {
  Heading as ThemeUIHeading,
  HeadingProps as ThemeUIHeadingProps,
} from 'theme-ui'
import { TensorUIBaseProps } from 'typ'

type HeadingVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
export type HeadingProps = Omit<ThemeUIHeadingProps, 'sx' | 'style'> &
  TensorUIBaseProps & {
    ellipsize?: boolean
    variant: HeadingVariant
  }

export const Heading = forwardRef<HTMLDivElement, HeadingProps>(
  ({ style, as = 'div', ellipsize, variant, ...props }, ref) => {
    const theme = useTheme()
    const ellipsisStyles = ellipsize ? theme.text.ellipsize : {}
    return (
      <ThemeUIHeading
        {...props}
        as={as}
        sx={{ ...style, ...ellipsisStyles }}
        ref={ref}
        variant={variant}
        role="heading"
      />
    )
  }
)

Heading.displayName = 'Heading'
