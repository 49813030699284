/** @jsxImportSource theme-ui */
import NextLink, { LinkProps } from 'next/link'
import { FC, PropsWithChildren } from 'react'
import { TensorUIBaseProps } from 'typ'
import { Button, ButtonProps } from './Button'

export interface NextLinkButtonProps extends PropsWithChildren {
  buttonProps?: ButtonProps
  linkProps: LinkProps & TensorUIBaseProps
  // This is used when we need to clone an element and pass it an extra/modified onClick handler
  onClick?: VoidFunction
}
export const NextLinkButton: FC<NextLinkButtonProps> = ({
  buttonProps,
  children,
  linkProps: { style: linkStyle, ...linkProps },
  onClick,
}) => (
  <NextLink
    sx={{
      display: 'block',
      width: 'max-content',
      color: 'inherit',
      textDecoration: 'none',
      borderRadius: 2,
      outline: 'none',
      ':focus-visible': {
        boxShadow: 'focusRing',
      },
      ...linkStyle,
    }}
    {...linkProps}
  >
    <Button as="div" {...buttonProps} onClick={buttonProps?.onClick ?? onClick}>
      {children}
    </Button>
  </NextLink>
)
