/** @jsxImportSource theme-ui */
import { useViewport } from '@/contexts/ViewportContext'
import { FC } from 'react'
import { Grid } from 'ui'
import dynamic from 'next/dynamic'
import { cssValueToNumber, useTheme } from 'theme'
import { HowItWorksHeading } from './HowItWorksHeading'

const HowItWorksDesktop = dynamic(() =>
  import('./HowItWorks').then((mod) => mod.HowItWorks)
)
const HowItWorksMobile = dynamic(() =>
  import('./HowItWorksMobile').then((mod) => mod.HowItWorksMobile)
)

export interface HowItWorksSectionProps {}
export const HowItWorksSection: FC<HowItWorksSectionProps> = () => {
  const { width } = useViewport()
  const theme = useTheme()
  const isMobile = width > 0
  const isDesktop = width >= cssValueToNumber(theme.breakpoints[1])
  return (
    <Grid
      as="section"
      style={{
        minHeight: '750px',
        placeItems: 'center',
        textAlign: 'center',
        my: [7, 9],
        mx: [4, null, null, null, 0],
      }}
      gap={5}
    >
      <HowItWorksHeading />
      {isDesktop ? (
        <HowItWorksDesktop />
      ) : isMobile ? (
        <HowItWorksMobile />
      ) : null}
    </Grid>
  )
}
