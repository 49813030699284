/** @jsxImportSource theme-ui */
import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Box, Grid, Heading, Text } from 'ui'
import Trans from 'next-translate/Trans'
import Image, { StaticImageData } from 'next/image'
import BatteryStorage from 'public/images/all-system-go/battery-storage.png'
import Forecasts from 'public/images/all-system-go/forecasts.png'
import Data from 'public/images/all-system-go/data.png'
import { DemoModalToggle } from '../DemoModalToggle'

export const d = {
  title: 'tensor-website-landing:all-systems-go.title',
  sub: 'tensor-website-landing:all-systems-go.sub',
  batteryStorageTitle:
    'tensor-website-landing:all-systems-go.battery-storage-title',
  batteryStorageDescription:
    'tensor-website-landing:all-systems-go.battery-storage-description',
  forecastsTitle: 'tensor-website-landing:all-systems-go.forecasts-title',
  forecastsDescription:
    'tensor-website-landing:all-systems-go.forecasts-description',
  dataTitle: 'tensor-website-landing:all-systems-go.data-title',
  dataDescription: 'tensor-website-landing:all-systems-go.data-description',
  cta: 'tensor-website-landing:all-systems-go.cta',
  batteryStorageImgAlt: 'tensor-website-landing:seo.battery-storage-img-alt',
  forecastsImgAlt: 'tensor-website-landing:seo.forecasts-img-alt',
  dataImgAlt: 'tensor-website-landing:seo.data-img-alt',
}

type FeatureBlockProps = {
  title: string
  src: string | StaticImageData
  alt: string
  description: string
}

const MIN_WIDTH = 'calc(100vw - 48px)'

const FeatureBlock: FC<FeatureBlockProps> = ({
  src,
  alt,
  title,
  description,
}) => {
  const imgBoxSizes = ['314px', null, null, '300px', '314px']
  return (
    <Grid
      style={{
        width: '100%',
        py: 3,
        textAlign: 'left',
        justifyItems: 'center',
        gap: 4,
        gridTemplateRows: ['314px 1fr'],
        '@media screen and (max-width: 350px)': {
          gridTemplateRows: [`${MIN_WIDTH} 1fr`],
        },
      }}
    >
      <Box
        style={{
          position: 'relative',
          height: imgBoxSizes,
          width: imgBoxSizes,
          '@media screen and (max-width: 350px)': {
            height: MIN_WIDTH,
            width: MIN_WIDTH,
          },
        }}
      >
        <Image
          sizes="(max-width: 768px) 314px, 314px"
          style={{ position: 'absolute', inset: 0 }}
          src={src}
          fill
          alt={alt}
        />
      </Box>
      <Box px={3}>
        <Heading variant="h6" as="h3" mb={1}>
          {title}
        </Heading>
        <Text variant="secondary2">{description}</Text>
      </Box>
    </Grid>
  )
}

export const AllSystemsGoSection: FC = () => {
  const { t } = useTranslation()

  const features: FeatureBlockProps[] = [
    {
      src: BatteryStorage,
      alt: t(d.batteryStorageImgAlt),
      title: t(d.batteryStorageTitle),
      description: t(d.batteryStorageDescription),
    },
    {
      src: Forecasts,
      alt: t(d.forecastsImgAlt),
      title: t(d.forecastsTitle),
      description: t(d.forecastsDescription),
    },
    {
      src: Data,
      alt: t(d.dataImgAlt),
      title: t(d.dataTitle),
      description: t(d.dataDescription),
    },
  ]

  return (
    <Grid
      as="section"
      gap={5}
      style={{
        placeItems: 'center',
        textAlign: 'center',
        mt: [0, 7, null, 9],
        mb: [7, 9],
        mx: 4,
      }}
    >
      <Grid style={{ placeItems: 'center' }}>
        <Heading
          as="h2"
          variant="h2"
          style={{
            fontWeight: 'semi-bold',
          }}
        >
          <Trans
            i18nKey={d.title}
            components={{
              br: <br />,
              nowrap: <span sx={{ whiteSpace: 'nowrap' }} />,
            }}
          />
        </Heading>
        <Text
          variant="title1"
          color="textGray"
          style={{
            maxWidth: ['720px', null, null, null, '900px'],
          }}
        >
          {t(d.sub)}
        </Text>
      </Grid>
      <Grid
        gap={4}
        style={{
          py: [0, null, null, null, '50px'],
          margin: 'auto',
          maxWidth: '1200px',
          gridTemplateColumns: ['342px', null, null, 'repeat(3, 1fr)'],
          '@media screen and (max-width: 350px)': {
            gridTemplateColumns: MIN_WIDTH,
          },
        }}
      >
        {features.map((feature) => (
          <FeatureBlock key={feature.title} {...feature} />
        ))}
      </Grid>
      <Grid style={{ placeItems: 'center' }}>
        <DemoModalToggle buttonText={t(d.cta)} size="lg" />
      </Grid>
    </Grid>
  )
}
