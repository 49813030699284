/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { StyledProps } from 'typ'

export const TensorNoNameSmallLogo: FC<StyledProps> = ({ style }) => (
  <svg
    width="60"
    height="47"
    viewBox="0 0 60 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="tensor-no-name-small-logo"
    sx={style}
  >
    <g style={{ mixBlendMode: 'multiply', opacity: '0.25' }}>
      <path
        d="M31.9317 46.0683H10.2529C9.11261 46.0683 8.46872 45.4582 8.81472 44.7057L14.3115 32.75C14.6575 31.9975 15.8624 31.3875 17.0026 31.3875H38.6815C39.8218 31.3875 40.4657 31.9975 40.1197 32.75L34.6229 44.7057C34.2769 45.4582 33.072 46.0683 31.9317 46.0683Z"
        fill="#4082FF"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply', opacity: '0.5' }}>
      <path
        d="M40.2171 33.796H5.63425C4.49396 33.796 3.85007 33.186 4.19607 32.4334L11.0428 17.5417C11.3888 16.7891 12.5937 16.1791 13.734 16.1791H48.3168C49.4571 16.1791 50.101 16.7891 49.755 17.5417L42.9082 32.4334C42.5622 33.186 41.3574 33.796 40.2171 33.796V33.796Z"
        fill="#4082FF"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M49.0187 21.0077H1.53171C0.391418 21.0077 -0.252473 20.3976 0.093527 19.6451L8.29026 1.81717C8.63626 1.06463 9.84109 0.45459 10.9814 0.45459H58.4683C59.6086 0.45459 60.2525 1.06463 59.9065 1.81717L51.7097 19.6451C51.3637 20.3976 50.1589 21.0077 49.0186 21.0077H49.0187Z"
        fill="#4082FF"
      />
    </g>
  </svg>
)
