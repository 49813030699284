/** @jsxImportSource theme-ui */
import { ComponentPropsWithoutRef, FC } from 'react'
import { StyledProps } from 'typ'

type LinesStyle = FC<
  Omit<ComponentPropsWithoutRef<'svg'>, 'style'> & StyledProps
>

export const RightLines: LinesStyle = ({ style, ...props }) => (
  <svg
    width="1010"
    height="825"
    viewBox="0 0 1010 825"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="right-lines"
    sx={{ ...style }}
    {...props}
  >
    <path
      d="M1009.54 824.986L1010 824.658C970.076 787.245 925.681 752.781 878.048 722.223C809.651 678.344 734.56 642.468 654.861 615.592C540.85 577.144 419.159 557.522 293.167 557.269C292.344 557.268 291.519 557.267 290.696 557.267C213.986 557.267 137.657 564.435 63.7845 578.579L63.9249 579.065C137.747 564.931 214.038 557.767 290.696 557.767C291.524 557.767 292.338 557.768 293.166 557.769C419.077 558.022 540.69 577.632 654.626 616.054C734.273 642.912 809.315 678.764 877.668 722.615C925.271 753.154 969.637 787.596 1009.54 824.986L1009.54 824.986Z"
      fill="url(#paint0_linear_5_321)"
    />
    <path
      d="M1007.49 798.391L1007.94 798.051C968.021 763.051 923.625 730.81 875.992 702.223C807.595 661.175 732.505 627.613 652.806 602.472C538.797 566.506 417.108 548.15 291.119 547.914C290.3 547.912 289.468 547.912 288.65 547.912C211.936 547.912 135.616 554.617 61.7406 567.849L61.8726 568.337C135.7 555.113 211.985 548.412 288.65 548.412C289.473 548.412 290.295 548.412 291.118 548.414C417.031 548.65 538.646 566.995 652.584 602.938C732.231 628.063 807.274 661.603 875.627 702.626C923.229 731.193 967.596 763.414 1007.49 798.391H1007.49Z"
      fill="url(#paint1_linear_5_321)"
    />
    <path
      d="M1005.45 771.797L1005.89 771.443C965.966 738.857 921.57 708.84 873.936 682.224C805.54 644.007 730.45 612.761 650.753 589.353C536.746 555.868 415.058 538.778 289.072 538.559C288.249 538.557 287.425 538.557 286.601 538.557C209.893 538.557 133.567 544.799 59.6978 557.117L59.8213 557.607C133.65 545.296 209.935 539.057 286.602 539.057C287.425 539.057 288.247 539.057 289.07 539.059C414.986 539.278 536.603 556.358 650.544 589.823C730.192 613.217 805.236 644.443 873.589 682.636C921.191 709.234 965.558 739.232 1005.46 771.797L1005.45 771.797Z"
      fill="url(#paint2_linear_5_321)"
    />
    <path
      d="M1003.42 745.202L1003.83 744.835C963.91 714.663 919.514 686.868 871.88 662.224C803.484 626.838 728.395 597.907 648.698 576.233C534.693 545.229 413.009 529.406 287.025 529.202C286.201 529.201 285.376 529.2 284.553 529.2C207.845 529.2 131.521 534.98 57.6552 546.387L57.7693 546.878C131.593 535.478 207.887 529.7 284.552 529.7C285.38 529.7 286.196 529.701 287.024 529.702C412.941 529.906 534.561 545.721 648.504 576.708C728.153 598.368 803.197 627.282 871.55 662.646C919.153 687.274 963.519 715.05 1003.42 745.202L1003.42 745.202Z"
      fill="url(#paint3_linear_5_321)"
    />
    <path
      d="M1001.38 718.607L1001.78 718.226C961.852 690.468 917.456 664.897 869.822 642.224C801.427 609.67 726.339 583.054 646.644 563.114C532.641 534.592 410.958 520.034 284.977 519.845C207.468 519.751 130.271 525.049 55.6118 535.655L55.7173 536.147C129.544 525.66 205.842 520.345 282.506 520.345C283.333 520.345 284.148 520.345 284.976 520.346C410.897 520.533 532.519 535.084 646.463 563.593C726.113 583.52 801.158 610.121 869.513 642.656C917.115 665.313 961.481 690.867 1001.38 718.607H1001.38Z"
      fill="url(#paint4_linear_5_321)"
    />
    <path
      d="M999.341 692.014L999.719 691.619C959.795 666.274 915.399 642.927 867.765 622.226C799.37 592.502 724.283 568.2 644.589 549.994C530.588 523.952 408.909 510.661 282.929 510.49C282.111 510.489 281.279 510.488 280.461 510.488C203.754 510.488 127.439 515.343 53.5685 524.924L53.6657 525.418C127.5 515.842 203.79 510.988 280.459 510.988C281.282 510.988 282.105 510.989 282.929 510.99C408.852 511.161 530.476 524.446 644.423 550.476C724.074 568.672 799.12 592.96 867.475 622.667C915.078 643.354 959.445 666.686 999.341 692.014Z"
      fill="url(#paint5_linear_5_321)"
    />
    <path
      d="M997.306 665.419L997.66 665.011C957.734 642.079 913.339 620.955 865.706 602.226C797.313 575.334 722.227 553.347 642.534 536.876C528.536 513.314 406.859 501.289 280.882 501.135C280.059 501.134 279.234 501.133 278.41 501.133C201.71 501.133 125.392 505.525 51.5261 514.193L51.6138 514.687C125.446 506.024 201.746 501.633 278.412 501.633C279.239 501.633 280.053 501.634 280.881 501.635C406.807 501.789 528.434 513.809 642.383 537.36C722.036 553.823 797.083 575.799 865.439 602.677C913.041 621.393 957.408 642.503 997.306 665.419V665.419Z"
      fill="url(#paint6_linear_5_321)"
    />
    <path
      d="M995.271 638.824L995.599 638.402C955.675 617.885 911.28 598.985 863.647 582.227C795.255 558.167 720.171 538.494 640.479 523.757C526.484 502.676 404.809 491.917 278.835 491.778C201.312 491.702 124.139 495.625 49.4827 503.463L49.5618 503.959C123.399 496.206 199.694 492.277 276.366 492.277C277.189 492.277 278.011 492.277 278.834 492.278C404.763 492.417 526.392 503.172 640.344 524.245C719.998 538.976 795.046 558.638 863.403 582.687C911.008 599.434 955.374 618.321 995.272 638.824L995.271 638.824Z"
      fill="url(#paint7_linear_5_321)"
    />
    <path
      d="M993.239 612.231L993.537 611.794C896.151 567.635 773.355 532.655 638.424 510.639C524.43 492.038 402.758 482.545 276.787 482.423C199.267 482.328 122.093 485.816 47.4402 492.732L47.5098 493.228C122.138 486.315 199.296 482.838 276.786 482.923C402.717 483.045 524.349 492.535 638.303 511.129C773.173 533.136 895.907 568.096 993.239 612.231Z"
      fill="url(#paint8_linear_5_321)"
    />
    <path
      d="M991.207 585.636L991.475 585.185C894.085 546.914 771.292 516.6 636.369 497.519C522.378 481.399 400.708 473.173 274.74 473.066C273.921 473.066 273.089 473.065 272.271 473.065C195.567 473.065 119.26 476.07 45.3976 482.001L45.4576 482.499C119.295 476.57 195.591 473.565 272.27 473.565C273.093 473.565 273.916 473.566 274.739 473.566C400.672 473.673 522.307 481.897 636.263 498.012C771.131 517.084 893.869 547.385 991.207 585.636L991.207 585.636Z"
      fill="url(#paint9_linear_5_321)"
    />
    <path
      d="M989.177 559.041L989.41 558.578C892.028 526.196 769.237 500.546 634.314 484.401C520.326 470.762 398.658 463.8 272.692 463.711C195.134 463.654 118.007 466.199 43.3542 471.27L43.4058 471.768C117.249 466.752 193.549 464.21 270.226 464.21C271.049 464.21 271.869 464.21 272.692 464.211C398.628 464.3 520.266 471.26 634.224 484.895C769.1 501.035 891.84 526.674 989.177 559.041Z"
      fill="url(#paint10_linear_5_321)"
    />
    <path
      d="M987.149 532.446L987.343 531.972C889.96 505.478 767.174 484.492 632.258 471.283C385.164 447.091 155.952 454.166 41.3116 460.54L41.3536 461.038C155.972 454.668 385.142 447.593 632.185 471.779C767.06 484.984 889.804 505.962 987.149 532.446V532.446Z"
      fill="url(#paint11_linear_5_321)"
    />
    <path
      d="M985.121 505.85L985.276 505.365C887.898 484.76 765.115 468.437 630.203 458.165C383.095 439.35 153.898 444.853 39.2692 449.809L39.3017 450.309C153.913 445.353 383.078 439.85 630.145 458.663C765.026 468.934 887.774 485.25 985.121 505.85H985.121Z"
      fill="url(#paint12_linear_5_321)"
    />
    <path
      d="M983.095 479.252L983.207 478.76C885.826 464.042 763.048 452.385 628.147 445.048C381.036 431.606 151.849 435.539 37.2262 439.079L37.2491 439.579C151.859 436.039 381.023 432.108 628.106 445.546C762.983 452.882 885.736 464.537 983.095 479.252Z"
      fill="url(#paint13_linear_5_321)"
    />
    <path
      d="M981.069 452.653L981.138 452.157C883.773 443.326 761 436.332 626.091 431.929C379.001 423.865 149.811 426.225 35.1832 428.349L35.1976 428.849C149.815 426.725 378.992 424.366 626.067 432.429C760.962 436.831 883.72 443.825 981.069 452.653Z"
      fill="url(#paint14_linear_5_321)"
    />
    <path
      d="M979.045 426.054L979.068 425.554C881.724 422.61 758.955 420.279 624.036 418.811C377.025 416.124 147.797 416.91 33.1407 417.618L33.1454 418.118C147.799 417.41 377.023 416.625 624.028 419.311C758.942 420.779 881.706 423.11 979.045 426.054H979.045Z"
      fill="url(#paint15_linear_5_321)"
    />
    <path
      d="M258.035 408.068C364.679 408.068 490.825 407.621 621.989 406.194C756.909 404.726 879.677 402.395 977.02 399.452L976.997 398.952C879.659 401.895 756.896 404.226 621.98 405.694C374.977 408.382 145.752 407.595 31.0982 406.888L31.0934 407.388C84.8663 407.72 163.845 408.068 258.035 408.068L258.035 408.068Z"
      fill="url(#paint16_linear_5_321)"
    />
    <path
      d="M255.956 398.701C362.597 398.701 488.753 397.358 619.949 393.077C754.85 388.674 877.622 381.68 974.995 372.85L974.927 372.353C877.569 381.182 754.811 388.175 619.925 392.577C372.87 400.64 143.681 398.281 29.0553 396.158L29.0409 396.658C82.804 397.653 161.769 398.701 255.956 398.701V398.701Z"
      fill="url(#paint17_linear_5_321)"
    />
    <path
      d="M253.909 389.333C360.55 389.333 486.701 387.096 617.91 379.959C752.815 372.622 875.593 360.964 972.97 346.246L972.858 345.754C875.503 360.469 752.75 372.124 617.869 379.46C370.802 392.897 141.63 388.967 27.0122 385.427L26.9894 385.927C80.7495 387.587 159.721 389.334 253.909 389.334V389.333Z"
      fill="url(#paint18_linear_5_321)"
    />
    <path
      d="M251.861 379.966C358.504 379.966 484.653 376.833 615.871 366.841C750.784 356.568 873.567 340.246 970.944 319.641L970.789 319.157C873.444 339.756 750.695 356.073 615.813 366.343C368.743 385.156 139.58 379.652 24.9698 374.697L24.9374 375.196C78.6963 377.521 157.672 379.966 251.861 379.966V379.966Z"
      fill="url(#paint19_linear_5_321)"
    />
    <path
      d="M249.811 370.598C356.457 370.598 482.618 366.569 613.831 353.723C748.749 340.514 871.535 319.528 968.916 293.035L968.722 292.56C871.38 319.043 748.635 340.021 613.758 353.226C366.703 377.413 137.54 370.338 22.9267 363.967L22.8847 364.466C76.6478 367.454 155.619 370.598 249.811 370.598V370.598Z"
      fill="url(#paint20_linear_5_321)"
    />
    <path
      d="M247.7 361.296C248.528 361.296 249.342 361.296 250.17 361.295C376.136 361.206 497.803 354.244 611.792 340.605C746.713 324.46 869.503 298.81 966.888 266.428L966.655 265.965C869.316 298.333 746.575 323.971 611.703 340.11C497.743 353.746 376.106 360.706 250.17 360.795C249.347 360.796 248.523 360.796 247.7 360.796C171.019 360.796 94.7227 358.254 20.8842 353.237L20.8326 353.735C94.6843 358.753 171.007 361.296 247.7 361.296L247.7 361.296Z"
      fill="url(#paint21_linear_5_321)"
    />
    <path
      d="M245.654 351.94C246.477 351.94 247.3 351.94 248.123 351.939C374.092 351.833 495.761 343.606 609.753 327.486C744.676 308.406 867.469 278.092 964.858 239.82L964.59 239.37C867.252 277.622 744.516 307.921 609.647 326.993C495.69 343.108 374.056 351.333 248.123 351.439C170.619 351.512 93.4721 348.499 18.8413 342.507L18.7814 343.004C92.6401 348.935 168.957 351.94 245.654 351.94H245.654Z"
      fill="url(#paint22_linear_5_321)"
    />
    <path
      d="M243.607 342.585C244.431 342.585 245.252 342.584 246.075 342.583C372.046 342.461 493.719 332.968 607.712 314.368C742.638 292.352 865.434 257.373 962.826 213.212L962.527 212.775C865.19 256.911 742.456 291.872 607.591 313.877C493.638 332.472 372.006 341.961 246.075 342.083C245.256 342.084 244.424 342.085 243.606 342.085C166.927 342.085 90.6401 338.618 16.7982 331.777L16.7287 332.274C90.5893 339.117 166.909 342.585 243.607 342.585L243.607 342.585Z"
      fill="url(#paint23_linear_5_321)"
    />
    <path
      d="M241.558 333.229C242.386 333.229 243.2 333.228 244.028 333.227C370.002 333.089 491.677 322.33 605.673 301.249C685.364 286.512 760.448 266.84 828.841 242.779C876.472 226.021 920.868 207.121 960.793 186.604L960.466 186.181C920.567 206.684 876.2 225.572 828.597 242.32C760.24 266.369 685.191 286.031 605.537 300.762C491.585 321.834 369.955 332.589 244.027 332.727C166.484 332.831 89.3848 328.883 14.7558 321.047L14.6765 321.543C88.5348 329.298 164.863 333.229 241.558 333.229L241.558 333.229Z"
      fill="url(#paint24_linear_5_321)"
    />
    <path
      d="M239.512 323.873C240.335 323.873 241.157 323.872 241.981 323.871C367.957 323.717 489.634 311.691 603.633 288.13C683.326 271.659 758.412 249.672 826.805 222.78C874.439 204.05 918.834 182.927 958.758 159.995L958.405 159.587C918.507 182.502 874.141 203.612 826.538 222.329C758.182 249.207 683.135 271.182 603.482 287.645C489.533 311.197 367.906 323.217 241.98 323.371C164.483 323.471 87.3388 319.075 12.7127 310.317L12.6251 310.812C86.4875 319.48 162.809 323.873 239.512 323.873V323.873Z"
      fill="url(#paint25_linear_5_321)"
    />
    <path
      d="M237.462 314.517C238.291 314.517 239.105 314.517 239.933 314.516C365.912 314.344 487.592 301.053 601.593 275.011C681.287 256.806 756.374 232.504 824.769 202.781C872.402 182.079 916.798 158.732 956.723 133.387L956.345 132.993C916.448 158.32 872.082 181.652 824.48 202.339C756.124 232.046 681.078 256.334 601.427 274.53C487.48 300.559 365.856 313.844 239.933 314.015C162.387 314.127 85.2959 309.266 10.6698 299.588L10.5726 300.082C84.4339 309.661 160.765 314.517 237.462 314.517V314.517Z"
      fill="url(#paint26_linear_5_321)"
    />
    <path
      d="M235.414 305.162C236.242 305.162 237.058 305.161 237.886 305.16C363.868 304.972 485.55 290.414 599.553 261.891C679.248 241.952 754.337 215.335 822.732 182.781C870.365 160.109 914.761 134.537 954.687 106.779L954.288 106.399C914.39 134.138 870.024 159.692 822.421 182.349C754.067 214.885 679.022 241.486 599.372 261.414C485.427 289.922 363.805 304.472 237.885 304.66C160.367 304.794 83.2506 299.459 8.62637 288.858L8.52087 289.35C82.3821 299.843 158.713 305.161 235.414 305.161L235.414 305.162Z"
      fill="url(#paint27_linear_5_321)"
    />
    <path
      d="M233.372 295.806C234.195 295.806 235.016 295.805 235.839 295.804C361.823 295.6 483.508 279.776 597.512 248.773C677.209 227.099 752.298 198.167 820.694 162.782C868.327 138.138 912.724 110.343 952.648 80.1703L952.23 79.804C912.333 109.956 867.967 137.732 820.365 162.36C752.011 197.724 676.967 226.638 597.318 248.298C483.375 279.285 361.756 295.1 235.838 295.304C235.019 295.305 234.188 295.306 233.37 295.306C156.698 295.306 80.4192 289.529 6.58371 278.128L6.46967 278.62C80.3375 290.025 156.666 295.806 233.372 295.806V295.806Z"
      fill="url(#paint28_linear_5_321)"
    />
    <path
      d="M231.321 286.45C232.148 286.45 232.963 286.449 233.791 286.448C359.778 286.228 481.465 269.138 595.472 235.653C675.169 212.246 750.259 181 818.655 142.782C866.288 116.167 910.684 86.1489 950.609 53.563L950.174 53.2095C910.276 85.7744 865.909 115.772 818.308 142.37C749.954 180.563 674.911 211.79 595.263 235.183C481.323 268.648 359.705 285.728 233.789 285.948C156.312 286.096 79.1618 279.842 4.54062 267.399L4.41705 267.889C78.2818 280.206 154.617 286.45 231.321 286.45Z"
      fill="url(#paint29_linear_5_321)"
    />
    <path
      d="M229.274 277.094C230.097 277.094 230.92 277.093 231.744 277.092C357.733 276.855 479.422 258.499 593.431 222.534C673.129 197.392 748.22 163.831 816.617 122.783C864.249 94.1963 908.645 61.955 948.57 26.955L948.12 26.6152C908.221 61.5924 863.854 93.8126 816.252 122.38C747.899 163.402 672.856 196.942 593.209 222.068C479.271 258.011 357.656 276.355 231.742 276.592C230.924 276.593 230.093 276.594 229.274 276.594C152.605 276.594 76.3298 269.893 2.49732 256.669L2.36536 257.158C76.2368 270.389 152.565 277.094 229.274 277.094H229.274Z"
      fill="url(#paint30_linear_5_321)"
    />
    <path
      d="M227.227 267.738C228.051 267.738 228.873 267.738 229.697 267.736C355.688 267.483 477.379 247.861 591.39 209.415C671.089 182.539 746.18 146.663 814.577 102.783C862.21 72.2246 906.605 37.7604 946.529 0.347755L946.065 0.0196533C906.167 37.4089 861.8 71.8516 814.197 102.391C745.844 146.241 670.803 182.094 591.155 208.953C477.219 247.374 355.606 266.984 229.695 267.236C228.877 267.238 228.045 267.238 227.227 267.238C150.559 267.238 74.2845 260.076 0.454523 245.94L0.314087 246.426C74.1855 260.57 150.516 267.738 227.227 267.738V267.738Z"
      fill="url(#paint31_linear_5_321)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5_321"
        x1="1010"
        y1="691.127"
        x2="63.7845"
        y2="691.127"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5_321"
        x1="1007.94"
        y1="673.151"
        x2="61.7406"
        y2="673.151"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5_321"
        x1="1005.89"
        y1="655.177"
        x2="59.6978"
        y2="655.177"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5_321"
        x1="1003.83"
        y1="637.201"
        x2="57.6552"
        y2="637.201"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_5_321"
        x1="1001.78"
        y1="619.226"
        x2="55.6118"
        y2="619.226"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_5_321"
        x1="999.719"
        y1="601.251"
        x2="53.5685"
        y2="601.251"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_5_321"
        x1="997.66"
        y1="583.276"
        x2="51.5261"
        y2="583.276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_5_321"
        x1="995.599"
        y1="565.301"
        x2="49.4827"
        y2="565.301"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_5_321"
        x1="993.537"
        y1="547.326"
        x2="47.4402"
        y2="547.326"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_5_321"
        x1="991.475"
        y1="529.35"
        x2="45.3976"
        y2="529.35"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_5_321"
        x1="989.409"
        y1="511.375"
        x2="43.3542"
        y2="511.375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_5_321"
        x1="987.343"
        y1="493.427"
        x2="41.3116"
        y2="493.427"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_5_321"
        x1="985.276"
        y1="475.445"
        x2="39.2692"
        y2="475.445"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_5_321"
        x1="983.207"
        y1="457.462"
        x2="37.2262"
        y2="457.462"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_5_321"
        x1="981.138"
        y1="439.479"
        x2="35.1832"
        y2="439.479"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_5_321"
        x1="979.068"
        y1="421.495"
        x2="33.1407"
        y2="421.495"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_5_321"
        x1="977.02"
        y1="403.51"
        x2="31.0934"
        y2="403.51"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_5_321"
        x1="974.995"
        y1="385.527"
        x2="29.0409"
        y2="385.527"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_5_321"
        x1="972.97"
        y1="367.544"
        x2="26.9894"
        y2="367.544"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_5_321"
        x1="970.944"
        y1="349.561"
        x2="24.9374"
        y2="349.561"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_5_321"
        x1="968.916"
        y1="331.579"
        x2="22.8847"
        y2="331.579"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_5_321"
        x1="966.888"
        y1="313.631"
        x2="20.8326"
        y2="313.631"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_5_321"
        x1="964.858"
        y1="295.655"
        x2="18.7814"
        y2="295.655"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_5_321"
        x1="962.826"
        y1="277.68"
        x2="16.7287"
        y2="277.68"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_5_321"
        x1="960.793"
        y1="259.705"
        x2="14.6765"
        y2="259.705"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_5_321"
        x1="958.758"
        y1="241.73"
        x2="12.6251"
        y2="241.73"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_5_321"
        x1="956.723"
        y1="223.755"
        x2="10.5726"
        y2="223.755"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_5_321"
        x1="954.687"
        y1="205.78"
        x2="8.52087"
        y2="205.78"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_5_321"
        x1="952.648"
        y1="187.805"
        x2="6.46967"
        y2="187.805"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_5_321"
        x1="950.609"
        y1="169.83"
        x2="4.41705"
        y2="169.83"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_5_321"
        x1="948.57"
        y1="151.855"
        x2="2.36536"
        y2="151.855"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_5_321"
        x1="946.529"
        y1="133.879"
        x2="0.314087"
        y2="133.879"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
    </defs>
  </svg>
)
