import { FC } from 'react'
import { Container, Heading, Text, Box, Grid, StyleObject } from 'ui'
import { DemoModalToggle } from '@/components/DemoModalToggle'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import { cssValueToNumber, useTheme } from 'theme'
import TCDashboardEN from 'public/images/tc-dashboard.png'
import TCDashboardJP from 'public/images/tc-dashboard-jp.png'
import { useViewport } from '@/contexts/ViewportContext'
import { LeftLines } from './LeftLines'
import { RightLines } from './RightLines'
import { Gradient } from './Gradient'

const linesStyle: StyleObject = {
  width: [null, null, '511px', null, '1023px'],
  height: [null, null, '412px', null, '825px'],
  position: 'absolute',
  top: [null, null, '0px', '26px', '-55px'],
}

const d = {
  mainTitle: 'tensor-website-landing:main-title',
  mainSub: 'tensor-website-landing:main-sub',
  mainCta: 'tensor-website-landing:main-cta',
  splashImgAlt: 'tensor-website-landing:seo.splash-img-alt',
}

export const SplashSection: FC = () => {
  const theme = useTheme()
  const { width } = useViewport()
  const showBackgroundElements = width >= cssValueToNumber(theme.breakpoints[1])
  const { t, lang } = useTranslation()

  return (
    <Container
      as="section"
      variant="unstyled"
      style={{
        display: 'grid',
        gap: 5,
        placeItems: 'center',
        textAlign: 'center',
        pt: [6, null, 8],
      }}
    >
      <Heading
        variant="h1"
        as="h1"
        style={{
          px: 4,
        }}
      >
        <Trans
          i18nKey={d.mainTitle}
          components={{
            br: <br />,
            nowrap: <span sx={{ whiteSpace: 'nowrap' }} />,
          }}
        />
      </Heading>
      <Text
        variant="title1"
        color="textGray"
        style={{ textAlign: 'center', maxWidth: '900px', px: 4 }}
      >
        {t(d.mainSub)}
      </Text>
      <DemoModalToggle size="lg" buttonText={t(d.mainCta)} />
      <Grid
        style={{
          placeItems: 'center',
          width: '100%',
          height: ['52vw', '304px', '575px', null, '1080px'],
          maxWidth: [null, null, '1000px', null, '1800px'],
        }}
      >
        <Grid
          style={{
            position: 'relative',
            placeItems: 'center',
            height: ['52vw', '304px', '432px', null, '675px'],
            width: '100%',
          }}
        >
          {showBackgroundElements && (
            <>
              <LeftLines
                style={{
                  ...linesStyle,
                  left: 0,
                }}
              />
              <Gradient
                style={{
                  width: '100%',
                  height: [null, null, '575px', null, '1080px'],
                  position: 'absolute',
                  top: [0, null, '-59px', null, '-180px'],
                }}
              />
              <RightLines
                style={{
                  ...linesStyle,
                  right: 0,
                }}
              />
            </>
          )}
          <Box
            style={{
              width: ['89vw', '540px', '768px', null, '1200px'],
              height: ['51vw', '304px', '432px', null, '675px'],
              position: 'absolute',
            }}
          >
            <Image
              src={lang === 'ja' ? TCDashboardJP : TCDashboardEN}
              fill
              sizes="(max-width: 600px) 90vw, 80vw"
              alt={t(d.splashImgAlt)}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
