/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { Box, Grid, Heading, StyleObject } from 'ui'
import Trans from 'next-translate/Trans'
import { TensorNoNameLogo } from '@/components/logos/TensorNoNameLogo'
import { TensorNoNameSmallLogo } from '@/components/logos/TensorNoNameSmallLogo'

export interface FlatTextCTAProps {
  titleI18nKey?: string
  textStyle?: StyleObject
}

const DynamicLogo = () => (
  <Box>
    <TensorNoNameLogo style={{ display: ['none', 'block'] }} />
    <TensorNoNameSmallLogo style={{ display: ['block', 'none'] }} />
  </Box>
)

export const d = {
  defaultTitle: 'tensor-website-common:cta.title',
}

export const FlatTextCTA: FC<React.PropsWithChildren<FlatTextCTAProps>> = ({
  titleI18nKey,
  children,
  textStyle = {},
}) => (
  <Grid style={{ placeItems: 'center', gap: 5, maxWidth: '900px' }}>
    <DynamicLogo />
    <Heading
      variant="h1"
      as="h2"
      style={{
        fontFamily: 'heading',
        fontWeight: 'semi-bold',
        textAlign: 'center',
        minWidth: 'auto',
        fontSize: ['8.75vw', '39px', '4.375vw', null, '67px'],
        lineHeight: ['11.375vw', '51px', '5.68vw', null, '87px'],
        ...textStyle,
      }}
    >
      <Trans
        i18nKey={titleI18nKey ?? d.defaultTitle}
        components={{
          br: <br />,
          nowrap: <span sx={{ whiteSpace: 'nowrap' }} />,
        }}
      />
    </Heading>
    {children}
  </Grid>
)
