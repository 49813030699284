/** @jsxImportSource theme-ui */
import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Grid, Heading, Text } from 'ui'
import Trans from 'next-translate/Trans'
import { d } from './dict'

export interface HowItWorksHeadingProps {}

export const HowItWorksHeading: FC<HowItWorksHeadingProps> = () => {
  const { t } = useTranslation()
  return (
    <Grid style={{ placeItems: 'center', textAlign: 'center' }}>
      <Heading
        as="h2"
        variant="h2"
        style={{
          fontWeight: 'semi-bold',
        }}
      >
        <Trans
          i18nKey={d.title}
          components={{ nowrap: <span style={{ whiteSpace: 'nowrap' }} /> }}
        />
      </Heading>
      <Text
        variant="title1"
        color="textGray"
        style={{
          maxWidth: ['720px', null, null, null, '900px'],
        }}
      >
        {t(d.sub)}
      </Text>
    </Grid>
  )
}
