/** @jsxImportSource theme-ui */
import { TopNavBar } from '@/components/navs/TopNavBar'
import { useViewport } from '@/contexts/ViewportContext'
import { FC, PropsWithChildren } from 'react'
import { Box } from 'ui'
import { Footer } from './Footer'

export interface WebsiteLayoutProps extends PropsWithChildren {}
export const WebsiteLayout: FC<WebsiteLayoutProps> = ({ children }) => {
  const { width } = useViewport()
  return (
    <Box
      style={{
        opacity: !width ? 0 : 1,
        overflowX: 'clip',
        position: 'relative',
      }}
    >
      <TopNavBar />
      <Box style={{ minHeight: 'calc(100vh - 330px)' }}>{children}</Box>
      <Footer />
    </Box>
  )
}
