/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { StyledProps } from 'typ'

export const TensorNoNameLogo: FC<StyledProps> = ({ style }) => (
  <svg
    width="120"
    height="92"
    viewBox="0 0 120 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="tensor-no-name-logo"
    sx={style}
  >
    <g style={{ mixBlendMode: 'multiply', opacity: '0.25' }}>
      <path
        d="M63.8635 91.2273H20.5058C18.2252 91.2273 16.9374 90.0072 17.6294 88.5021L28.623 64.5908C29.315 63.0857 31.7247 61.8657 34.0053 61.8657H77.363C79.6435 61.8657 80.9313 63.0857 80.2393 64.5908L69.2457 88.5021C68.5537 90.0072 66.1441 91.2273 63.8635 91.2273Z"
        fill="#4082FF"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply', opacity: '0.5' }}>
      <path
        d="M80.4342 66.6829H11.2685C8.98791 66.6829 7.70013 65.4628 8.39213 63.9578L22.0857 34.1742C22.7777 32.6691 25.1873 31.449 27.4679 31.449H96.6337C98.9142 31.449 100.202 32.6691 99.51 34.1742L85.8165 63.9578C85.1245 65.4628 82.7148 66.6829 80.4342 66.6829V66.6829Z"
        fill="#4082FF"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M98.0373 41.1062H3.06342C0.782836 41.1062 -0.504946 39.8861 0.187054 38.381L16.5805 2.72516C17.2725 1.22007 19.6822 0 21.9628 0H116.937C119.217 0 120.505 1.22007 119.813 2.72516L103.419 38.381C102.727 39.8861 100.318 41.1062 98.0372 41.1062H98.0373Z"
        fill="#4082FF"
      />
    </g>
  </svg>
)
