/** @jsxImportSource theme-ui */
import { ComponentPropsWithoutRef, FC } from 'react'
import { StyledProps } from 'typ'

type LinesStyle = FC<
  Omit<ComponentPropsWithoutRef<'svg'>, 'style'> & StyledProps
>

export const LeftLines: LinesStyle = ({ style, ...props }) => (
  <svg
    width="1023"
    height="825"
    viewBox="0 0 1023 825"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="left-lines"
    sx={{ ...style }}
    {...props}
  >
    <path
      d="M0.470398 824.986L0 824.658C40.4473 787.245 85.4238 752.781 133.681 722.223C202.974 678.344 279.048 642.468 359.791 615.592C475.296 577.144 598.58 557.522 726.222 557.269C727.057 557.268 727.892 557.267 728.726 557.267C806.441 557.267 883.769 564.435 958.609 578.579L958.467 579.065C883.678 564.931 806.388 557.767 728.726 557.767C727.887 557.767 727.063 557.768 726.224 557.769C598.663 558.022 475.458 577.632 360.029 616.054C279.339 642.912 203.314 678.764 134.066 722.615C85.8393 753.154 40.8914 787.596 0.470215 824.986L0.470398 824.986Z"
      fill="url(#paint0_linear_5_354)"
    />
    <path
      d="M2.53784 798.391L2.08209 798.051C42.5294 763.051 87.5065 730.81 135.763 702.223C205.056 661.175 281.13 627.613 361.872 602.472C477.375 566.506 600.658 548.15 728.297 547.914C729.126 547.912 729.97 547.912 730.799 547.912C808.518 547.912 885.837 554.617 960.68 567.849L960.546 568.337C885.752 555.113 808.468 548.412 730.799 548.412C729.965 548.412 729.132 548.412 728.298 548.414C600.736 548.65 477.529 566.995 362.097 602.938C281.407 628.063 205.381 661.603 136.133 702.626C87.9071 731.193 42.9592 763.414 2.53796 798.391H2.53784Z"
      fill="url(#paint1_linear_5_354)"
    />
    <path
      d="M4.60455 771.797L4.16394 771.443C44.6113 738.857 89.5885 708.84 137.846 682.224C207.139 644.007 283.212 612.761 363.953 589.353C479.453 555.868 602.734 538.778 730.371 538.559C731.205 538.557 732.04 538.557 732.874 538.557C810.587 538.557 887.912 544.799 962.75 557.117L962.625 557.607C887.829 545.296 810.544 539.057 732.873 539.057C732.04 539.057 731.206 539.057 730.373 539.059C602.807 539.278 479.597 556.358 364.165 589.823C283.473 613.217 207.447 644.443 138.198 682.636C89.9724 709.234 45.0245 739.232 4.60437 771.797L4.60455 771.797Z"
      fill="url(#paint2_linear_5_354)"
    />
    <path
      d="M6.66949 745.202L6.24664 744.835C46.694 714.663 91.6716 686.868 139.93 662.224C209.221 626.838 285.294 597.907 366.035 576.233C481.533 545.229 604.811 529.406 732.445 529.202C733.279 529.201 734.115 529.2 734.949 529.2C812.662 529.2 889.986 534.98 964.819 546.387L964.704 546.878C889.913 535.478 812.619 529.7 734.95 529.7C734.111 529.7 733.285 529.701 732.446 529.702C604.879 529.906 481.667 545.721 366.232 576.708C285.539 598.368 209.512 627.282 140.263 662.646C92.0375 687.274 47.0903 715.05 6.66962 745.202L6.66949 745.202Z"
      fill="url(#paint3_linear_5_354)"
    />
    <path
      d="M8.73456 718.607L8.33044 718.226C48.779 690.468 93.7565 664.897 142.014 642.224C211.305 609.67 287.377 583.054 368.116 563.114C483.612 534.592 606.888 520.034 734.52 519.845C813.044 519.751 891.252 525.049 966.889 535.655L966.782 536.147C891.988 525.66 814.692 520.345 737.023 520.345C736.185 520.345 735.36 520.345 734.521 520.346C606.951 520.533 483.736 535.084 368.299 563.593C287.606 583.52 211.577 610.121 142.327 642.656C94.1022 665.313 49.1548 690.867 8.73425 718.607H8.73456Z"
      fill="url(#paint4_linear_5_354)"
    />
    <path
      d="M10.7983 692.014L10.4154 691.619C50.8627 666.274 95.8404 642.927 144.098 622.226C213.389 592.502 289.459 568.2 370.198 549.994C485.691 523.952 608.965 510.661 736.594 510.49C737.423 510.489 738.266 510.488 739.095 510.488C816.807 510.488 894.121 515.343 968.959 524.924L968.861 525.418C894.06 515.842 816.77 510.988 739.096 510.988C738.263 510.988 737.429 510.989 736.595 510.99C609.022 511.161 485.805 524.446 370.366 550.476C289.671 568.672 213.642 592.96 144.391 622.667C96.1651 643.354 51.2177 666.686 10.7983 692.014Z"
      fill="url(#paint5_linear_5_354)"
    />
    <path
      d="M12.8599 665.419L12.5013 665.011C52.9506 642.079 97.9275 620.955 146.184 602.226C215.473 575.334 291.542 553.347 372.279 536.876C487.77 513.314 611.042 501.289 738.668 501.135C739.502 501.134 740.338 501.133 741.173 501.133C818.877 501.133 896.195 505.525 971.028 514.193L970.94 514.687C896.141 506.024 818.841 501.633 741.171 501.633C740.332 501.633 739.508 501.634 738.669 501.635C611.094 501.789 487.874 513.809 372.432 537.36C291.736 553.823 215.706 575.799 146.454 602.677C98.2292 621.393 53.2812 642.503 12.8599 665.419V665.419Z"
      fill="url(#paint6_linear_5_354)"
    />
    <path
      d="M14.9214 638.824L14.5897 638.402C55.0363 617.885 100.013 598.985 148.27 582.227C217.557 558.167 293.626 538.494 374.361 523.757C489.85 502.676 613.118 491.917 740.742 491.778C819.28 491.702 897.464 495.625 973.099 503.463L973.018 503.959C898.214 496.206 820.92 492.277 743.243 492.277C742.409 492.277 741.577 492.277 740.743 492.278C613.165 492.417 489.943 503.172 374.498 524.245C293.801 538.976 217.769 558.638 148.517 582.687C100.289 599.434 55.3412 618.321 14.9213 638.824L14.9214 638.824Z"
      fill="url(#paint7_linear_5_354)"
    />
    <path
      d="M16.981 612.231L16.6783 611.794C115.34 567.635 239.745 532.655 376.443 510.639C491.93 492.038 615.196 482.545 742.817 482.423C821.353 482.328 899.538 485.816 975.168 492.732L975.097 493.228C899.492 486.315 821.323 482.838 742.818 482.923C615.237 483.045 492.012 492.535 376.566 511.129C239.929 533.136 115.587 568.096 16.981 612.231Z"
      fill="url(#paint8_linear_5_354)"
    />
    <path
      d="M19.0392 585.636L18.7681 585.185C117.433 546.914 241.835 516.6 378.525 497.519C494.009 481.399 617.273 473.173 744.891 473.066C745.721 473.066 746.564 473.065 747.393 473.065C825.1 473.065 902.407 476.07 977.237 482.001L977.176 482.499C902.371 476.57 825.076 473.565 747.393 473.565C746.56 473.565 745.726 473.566 744.892 473.566C617.309 473.673 494.081 481.897 378.632 498.012C241.998 517.084 117.652 547.385 19.0393 585.636L19.0392 585.636Z"
      fill="url(#paint9_linear_5_354)"
    />
    <path
      d="M21.096 559.041L20.8602 558.578C119.518 526.196 243.916 500.546 380.607 484.401C496.088 470.762 619.349 463.8 746.965 463.711C825.539 463.654 903.677 466.199 979.307 471.27L979.255 471.768C904.445 466.752 827.145 464.21 749.464 464.21C748.63 464.21 747.799 464.21 746.966 464.211C619.38 464.3 496.149 471.26 380.698 484.895C244.055 501.035 119.707 526.674 21.096 559.041Z"
      fill="url(#paint10_linear_5_354)"
    />
    <path
      d="M23.1508 532.446L22.9539 531.972C121.612 505.478 246.006 484.492 382.69 471.283C633.021 447.091 865.235 454.166 981.377 460.54L981.334 461.038C865.214 454.668 633.043 447.593 382.764 471.779C246.122 484.984 121.77 505.962 23.1508 532.446V532.446Z"
      fill="url(#paint11_linear_5_354)"
    />
    <path
      d="M25.2048 505.85L25.048 505.365C123.702 484.76 248.093 468.437 384.772 458.165C635.116 439.35 867.316 444.853 983.446 449.809L983.413 450.309C867.301 445.353 635.133 439.85 384.83 458.663C248.183 468.934 123.827 485.25 25.2047 505.85H25.2048Z"
      fill="url(#paint12_linear_5_354)"
    />
    <path
      d="M27.2576 479.252L27.144 478.76C125.801 464.042 250.187 452.385 386.855 445.048C637.202 431.606 869.392 435.539 985.516 439.079L985.493 439.579C869.382 436.039 637.216 432.108 386.896 445.546C250.252 452.882 125.891 464.537 27.2576 479.252Z"
      fill="url(#paint13_linear_5_354)"
    />
    <path
      d="M29.3096 452.653L29.2403 452.157C127.88 443.326 252.261 436.332 388.938 431.929C639.265 423.865 871.456 426.225 987.585 428.349L987.571 428.849C871.452 426.725 639.273 424.366 388.962 432.429C252.3 436.831 127.934 443.825 29.3096 452.653Z"
      fill="url(#paint14_linear_5_354)"
    />
    <path
      d="M31.3606 426.054L31.3374 425.554C129.957 422.61 254.333 420.279 391.02 418.811C641.266 416.124 873.497 416.91 989.655 417.618L989.65 418.118C873.494 417.41 641.268 416.625 391.028 419.311C254.346 420.779 129.975 423.11 31.3605 426.054H31.3606Z"
      fill="url(#paint15_linear_5_354)"
    />
    <path
      d="M761.815 408.068C653.774 408.068 525.976 407.621 393.094 406.194C256.406 404.726 132.03 402.395 33.412 399.452L33.4351 398.952C132.048 401.895 256.419 404.226 393.102 405.694C643.341 408.382 875.568 407.595 991.724 406.888L991.729 407.388C937.252 407.72 857.239 408.068 761.815 408.068L761.815 408.068Z"
      fill="url(#paint16_linear_5_354)"
    />
    <path
      d="M763.92 398.701C655.883 398.701 528.075 397.358 395.16 393.077C258.492 388.674 134.112 381.68 35.4631 372.85L35.5323 372.353C134.166 381.182 258.531 388.175 395.185 392.577C645.475 400.64 877.666 398.281 993.794 396.158L993.808 396.658C939.341 397.653 859.341 398.701 763.92 398.701V398.701Z"
      fill="url(#paint17_linear_5_354)"
    />
    <path
      d="M765.995 389.333C657.957 389.333 530.153 387.096 397.226 379.959C260.554 372.622 136.168 360.964 37.5148 346.246L37.6284 345.754C136.259 360.469 260.62 372.124 397.267 379.46C647.571 392.897 879.745 388.967 995.863 385.427L995.887 385.927C941.422 387.587 861.417 389.334 765.995 389.334V389.333Z"
      fill="url(#paint18_linear_5_354)"
    />
    <path
      d="M768.07 379.966C660.03 379.966 532.228 376.833 399.292 366.841C262.611 356.568 138.22 340.246 39.5679 319.641L39.7247 319.157C138.345 339.756 262.701 356.073 399.35 366.343C649.657 385.156 881.821 379.652 997.933 374.697L997.965 375.196C943.502 377.521 863.492 379.966 768.07 379.966V379.966Z"
      fill="url(#paint19_linear_5_354)"
    />
    <path
      d="M770.146 370.598C662.103 370.598 534.29 366.569 401.358 353.723C264.673 340.514 140.278 319.528 41.6222 293.035L41.8191 292.56C140.436 319.043 264.788 340.021 401.433 353.226C651.723 377.413 883.888 370.338 1000 363.967L1000.04 364.466C945.578 367.454 865.572 370.598 770.146 370.598V370.598Z"
      fill="url(#paint20_linear_5_354)"
    />
    <path
      d="M772.285 361.296C771.446 361.296 770.621 361.296 769.782 361.295C642.166 361.206 518.906 354.244 403.424 340.605C266.736 324.46 142.337 298.81 43.6768 266.428L43.9126 265.965C142.527 298.333 266.875 323.971 403.515 340.11C518.967 353.746 642.197 360.706 769.783 360.795C770.617 360.796 771.452 360.796 772.285 360.796C849.97 360.796 927.266 358.254 1002.07 353.237L1002.12 353.735C927.305 358.753 849.982 361.296 772.285 361.296L772.285 361.296Z"
      fill="url(#paint21_linear_5_354)"
    />
    <path
      d="M774.358 351.94C773.524 351.94 772.691 351.94 771.856 351.939C644.237 351.833 520.974 343.606 405.49 327.486C268.799 308.406 144.398 278.092 45.7338 239.82L46.0048 239.37C144.617 277.622 268.962 307.921 405.597 326.993C521.046 343.108 644.274 351.333 771.857 351.439C850.375 351.512 928.533 348.499 1004.14 342.507L1004.2 343.004C929.376 348.935 852.059 351.94 774.357 351.94H774.358Z"
      fill="url(#paint22_linear_5_354)"
    />
    <path
      d="M776.432 342.585C775.597 342.585 774.765 342.584 773.931 342.583C646.31 342.461 523.044 332.968 407.557 314.368C270.864 292.352 146.459 257.373 47.7921 213.212L48.0947 212.775C146.707 256.911 271.049 291.872 407.68 313.877C523.126 332.472 646.351 341.961 773.932 342.083C774.761 342.084 775.603 342.085 776.432 342.085C854.116 342.085 931.402 338.618 1006.21 331.777L1006.28 332.274C931.454 339.117 854.135 342.585 776.431 342.585L776.432 342.585Z"
      fill="url(#paint23_linear_5_354)"
    />
    <path
      d="M778.507 333.229C777.668 333.229 776.843 333.228 776.005 333.227C648.381 333.089 525.112 322.33 409.624 301.249C328.888 286.512 252.82 266.84 183.532 242.779C135.277 226.021 90.2995 207.121 49.8516 186.604L50.1833 186.181C90.6046 206.684 135.552 225.572 183.779 242.32C253.032 266.369 329.063 286.031 409.761 300.762C525.205 321.834 648.428 332.589 776.006 332.727C854.565 332.831 932.674 328.883 1008.28 321.047L1008.36 321.543C933.535 329.298 856.207 333.229 778.508 333.229L778.507 333.229Z"
      fill="url(#paint24_linear_5_354)"
    />
    <path
      d="M780.58 323.873C779.747 323.873 778.913 323.872 778.079 323.871C650.452 323.717 527.181 311.691 411.69 288.13C330.953 271.659 254.883 249.672 185.594 222.78C137.337 204.05 92.3599 182.927 51.9126 159.995L52.2712 159.587C92.6911 182.502 137.639 203.612 185.865 222.329C255.117 249.207 331.146 271.182 411.843 287.645C527.285 311.197 650.505 323.217 778.08 323.371C856.592 323.471 934.747 319.075 1010.35 310.317L1010.44 310.812C935.609 319.48 858.288 323.873 780.58 323.873V323.873Z"
      fill="url(#paint25_linear_5_354)"
    />
    <path
      d="M782.657 314.517C781.817 314.517 780.992 314.517 780.154 314.516C652.525 314.344 529.251 301.053 413.757 275.011C333.019 256.806 256.948 232.504 187.658 202.781C139.401 182.079 94.4232 158.732 53.9746 133.387L54.3574 132.993C94.778 158.32 139.725 181.652 187.951 202.339C257.201 232.046 333.23 256.334 413.925 274.53C529.364 300.559 652.582 313.844 780.154 314.015C858.715 314.127 936.816 309.266 1012.42 299.588L1012.52 300.082C937.69 309.661 860.359 314.517 782.657 314.517V314.517Z"
      fill="url(#paint26_linear_5_354)"
    />
    <path
      d="M784.732 305.162C783.893 305.162 783.066 305.161 782.228 305.16C654.596 304.972 531.32 290.414 415.823 261.891C335.084 241.952 259.012 215.335 189.721 182.781C141.464 160.109 96.4863 134.537 56.0378 106.779L56.442 106.399C96.8625 134.138 141.81 159.692 190.036 182.349C259.285 214.885 335.313 241.486 416.006 261.414C531.444 289.922 654.659 304.472 782.229 304.66C860.762 304.794 938.888 299.459 1014.49 288.858L1014.6 289.35C939.768 299.843 862.437 305.161 784.732 305.161L784.732 305.162Z"
      fill="url(#paint27_linear_5_354)"
    />
    <path
      d="M786.801 295.806C785.967 295.806 785.135 295.805 784.302 295.804C656.667 295.6 533.388 279.776 417.891 248.773C337.15 227.099 261.077 198.167 191.786 162.782C143.528 138.138 98.5505 110.343 58.1032 80.1703L58.5262 79.804C98.9467 109.956 143.893 137.732 192.119 162.36C261.368 197.724 337.395 226.638 418.087 248.298C533.523 279.285 656.735 295.1 784.302 295.304C785.132 295.305 785.973 295.306 786.802 295.306C864.479 295.306 941.757 289.529 1016.56 278.128L1016.68 278.62C941.84 290.025 864.511 295.806 786.801 295.806V295.806Z"
      fill="url(#paint28_linear_5_354)"
    />
    <path
      d="M788.879 286.45C788.04 286.45 787.215 286.449 786.376 286.448C658.739 286.228 535.458 269.138 419.958 235.653C339.216 212.246 263.143 181 193.851 142.782C145.594 116.167 100.617 86.1489 60.1688 53.563L60.6094 53.2095C101.03 85.7744 145.978 115.772 194.203 142.37C263.452 180.563 339.478 211.79 420.17 235.183C535.602 268.648 658.812 285.728 786.378 285.948C864.87 286.096 943.031 279.842 1018.63 267.399L1018.75 267.889C943.922 280.206 866.587 286.45 788.879 286.45Z"
      fill="url(#paint29_linear_5_354)"
    />
    <path
      d="M790.952 277.094C790.118 277.094 789.284 277.093 788.45 277.092C660.811 276.855 537.528 258.499 422.025 222.534C341.283 197.392 265.209 163.831 195.916 122.783C147.66 94.1963 102.683 61.955 62.235 26.955L62.6908 26.6152C103.113 61.5924 148.06 93.8126 196.286 122.38C265.534 163.402 341.56 196.942 422.25 222.068C537.681 258.011 660.889 276.355 788.452 276.592C789.281 276.593 790.123 276.594 790.953 276.594C868.626 276.594 945.9 269.893 1020.7 256.669L1020.83 257.158C945.994 270.389 868.666 277.094 790.953 277.094H790.952Z"
      fill="url(#paint30_linear_5_354)"
    />
    <path
      d="M793.026 267.738C792.191 267.738 791.358 267.738 790.524 267.736C662.882 267.483 539.597 247.861 424.093 209.415C343.35 182.539 267.275 146.663 197.983 102.783C149.725 72.2246 104.749 37.7604 64.3022 0.347755L64.7726 0.0196533C105.193 37.4089 150.141 71.8516 198.368 102.391C267.616 146.241 343.64 182.094 424.331 208.953C539.76 247.374 662.965 266.984 790.526 267.236C791.355 267.238 792.197 267.238 793.026 267.238C870.699 267.238 947.972 260.076 1022.77 245.94L1022.91 246.426C948.072 260.57 870.742 267.738 793.026 267.738V267.738Z"
      fill="url(#paint31_linear_5_354)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5_354"
        x1="0"
        y1="691.127"
        x2="958.609"
        y2="691.127"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5_354"
        x1="2.08209"
        y1="673.151"
        x2="960.68"
        y2="673.151"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5_354"
        x1="4.16394"
        y1="655.177"
        x2="962.75"
        y2="655.177"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5_354"
        x1="6.24664"
        y1="637.201"
        x2="964.819"
        y2="637.201"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_5_354"
        x1="8.33044"
        y1="619.226"
        x2="966.889"
        y2="619.226"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_5_354"
        x1="10.4154"
        y1="601.251"
        x2="968.959"
        y2="601.251"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_5_354"
        x1="12.5014"
        y1="583.276"
        x2="971.028"
        y2="583.276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_5_354"
        x1="14.5896"
        y1="565.301"
        x2="973.099"
        y2="565.301"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_5_354"
        x1="16.6783"
        y1="547.326"
        x2="975.168"
        y2="547.326"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_5_354"
        x1="18.7681"
        y1="529.35"
        x2="977.237"
        y2="529.35"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_5_354"
        x1="20.8602"
        y1="511.375"
        x2="979.307"
        y2="511.375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_5_354"
        x1="22.9539"
        y1="493.427"
        x2="981.377"
        y2="493.427"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_5_354"
        x1="25.048"
        y1="475.445"
        x2="983.446"
        y2="475.445"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_5_354"
        x1="27.1439"
        y1="457.462"
        x2="985.516"
        y2="457.462"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_5_354"
        x1="29.2403"
        y1="439.479"
        x2="987.585"
        y2="439.479"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_5_354"
        x1="31.3374"
        y1="421.495"
        x2="989.655"
        y2="421.495"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_5_354"
        x1="33.412"
        y1="403.51"
        x2="991.729"
        y2="403.51"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_5_354"
        x1="35.4631"
        y1="385.527"
        x2="993.808"
        y2="385.527"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_5_354"
        x1="37.5148"
        y1="367.544"
        x2="995.887"
        y2="367.544"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_5_354"
        x1="39.568"
        y1="349.561"
        x2="997.965"
        y2="349.561"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_5_354"
        x1="41.6222"
        y1="331.579"
        x2="1000.04"
        y2="331.579"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_5_354"
        x1="43.6768"
        y1="313.631"
        x2="1002.12"
        y2="313.631"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_5_354"
        x1="45.7338"
        y1="295.655"
        x2="1004.2"
        y2="295.655"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_5_354"
        x1="47.7921"
        y1="277.68"
        x2="1006.28"
        y2="277.68"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_5_354"
        x1="49.8516"
        y1="259.705"
        x2="1008.36"
        y2="259.705"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_5_354"
        x1="51.9127"
        y1="241.73"
        x2="1010.44"
        y2="241.73"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_5_354"
        x1="53.9745"
        y1="223.755"
        x2="1012.52"
        y2="223.755"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_5_354"
        x1="56.0378"
        y1="205.78"
        x2="1014.6"
        y2="205.78"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_5_354"
        x1="58.1032"
        y1="187.805"
        x2="1016.68"
        y2="187.805"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_5_354"
        x1="60.1688"
        y1="169.83"
        x2="1018.75"
        y2="169.83"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_5_354"
        x1="62.235"
        y1="151.855"
        x2="1020.83"
        y2="151.855"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_5_354"
        x1="64.3022"
        y1="133.879"
        x2="1022.91"
        y2="133.879"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" />
      </linearGradient>
    </defs>
  </svg>
)
