/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
// eslint-disable-next-line no-restricted-imports
import {
  Divider as ThemeUIDivider,
  DividerProps as ThemeUIDividerProps,
} from 'theme-ui'
import { TensorUIBaseProps } from 'typ'

export type DividerProps = Omit<ThemeUIDividerProps, 'sx' | 'style'> &
  TensorUIBaseProps

export const Divider = forwardRef<HTMLDivElement, DividerProps>(
  ({ style, ...props }, ref) => (
    <ThemeUIDivider {...props} sx={style} ref={ref} />
  )
)

Divider.displayName = 'Divider'
